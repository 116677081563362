
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useAuthStore } from "@/store/AuthStore"
import moment from 'moment';
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import router from '@/router';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

export default defineComponent({
  name: "StockPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    ElConfigProvider,
  },
  setup() {
    const postObject = ref({
      data: {
        id_company: null, 
        id_store: null, 
        id_product_stores: null, 
        quantity: 1,
        movement_type: null, 
        movement_id: null,
        reason: null,
        invoice_issue_date: null
      } as any
    })
    const measure = ref('Unidade')
    const value = ref()
    const isLoadingStores = ref(false)
    const isLoadingProducts = ref(false)
    const products = ref()
    const descFraction = ref("")

    const options = ref({
      company: [] as any,
      stores: [] as any,
      product: [] as any,
      quantity: 0,
      movement_type: ['input', 'return'],
      movement_id: ""
    })

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const loaderStore = useLoaderStore();
    const authStore = useAuthStore()
    const filterBaseV2Store = useFilterBaseV2Store()

   async function getStores() {
        isLoadingStores.value = true
        try {
          loaderStore.open()
          const response = await axios.get(`/api/getStore?id_company=${ postObject.value.data.id_company || null }`)
          options.value.stores = response.data.data
        } catch (error) {
          
        } finally {
          loaderStore.close()
          isLoadingStores.value = false
        }
   }

    async function getProducts(id) {
    isLoadingProducts.value = true
        try {
          loaderStore.open()
          const response = await axios.get(`/api/getStoreProduct/${id}`)
          const newProduct = response.data.data.map(elem => {
            return {
              id: elem.id,
              nameFilter: `${elem.name},${elem.sku}`,
              name: elem.name,
              sku: elem.sku,
              fraction: elem.fraction,
            }
          });
          options.value.product = newProduct
        } catch (error) {
          
        } finally {
          isLoadingProducts.value = false
          loaderStore.close()
        }
    }
  
    const { showTimeAlert, showTimeConfirm } = useAlert()

    async function onSubmitForm() {
      const { data } = postObject.value

      if(
        !data.id_company ||
        !data.id_store ||
        !data.id_product_stores ||
        !data.quantity ||
        !data.movement_type ||
        !data.movement_id
      ) {
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const company = await filterBaseV2Store.getCompanyInSession()
        if(!company) {
          loaderStore.close()
          return showTimeAlert("É necessário ter uma companhia setada", "error")
        }

        const { invoice_issue_date } = postObject.value.data
        postObject.value.data.invoice_issue_date = invoice_issue_date ? moment(invoice_issue_date).format('YYYY-MM-DD HH:mm:ss') : null
        const { data } = postObject.value
        if(data) {
          if(authStore.getUserLevel > 100) {
            data.id_company = company.id
          }
          const result = await axios.post(`/api/postStock`, data)
            showTimeAlert("Registro criado com sucesso!")
            return router.push("/estoque")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado!", "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    // Life cycles
    watch(() => postObject.value.data.id_company, () => {
      postObject.value.data.id_store = ""
      options.value.stores = []
      postObject.value.data.id_product_stores = ""
      options.value.product = []
      getStores()
    })

    watch(() => postObject.value.data.id_store, () => {
      postObject.value.data.id_product_stores = ""
      options.value.product = []
      getProducts(postObject.value.data.id_store)
    })
  
    onMounted(async() => {
      try {
        const company = await filterBaseV2Store.getCompanyInSession()
        if(!company) {
          return showTimeAlert("É necessário ter uma companhia setada", "error")
        }

        const response = await axios.get(`/api/getCompanyList`)
        options.value.company = response.data.data
        if(authStore.getUserLevel > 200) {
        postObject.value.data.id_company = company.id
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
      getStores()
    })

    watch(() => postObject.value.data.id_product_stores, () => {
      descFraction.value = ""
      const measureFilter = options.value.product.filter(elem => elem.id == postObject.value.data.id_product_stores)
      if(measureFilter[0]?.fraction && postObject.value.data.movement_type === "input") {
        descFraction.value = measureFilter[0]?.fraction
        showTimeConfirm(
          "Produto Fracionado!",
          `Este produto é vendido em ${measureFilter[0]?.fraction} no campo quantidade, informe o total de ${measureFilter[0]?.fraction}!`,
          "Ok",
          "Aprovado com sucesso!",
          () => {},
          "warning",
          ""
        )
        measure.value = measureFilter[0].fraction
      }
    })

   function renderLabel(product) {
     const nameParts = product.nameFilter.split(',');
     const name = nameParts[0];
     const sku = nameParts[1];
    if (product.id === postObject.value.data.id_product_stores) {
        return `${name}`;
      } else {
        return `${name}, ${sku}`
    }
  }

    watch(() => postObject.value.data, () => isRequiredField.value = false, { deep: true })

    return {
      postObject,
      isRequiredField,
      isInvalidFieldWarning,
      onSubmitForm,
      value,
      options,
      isLoadingStores,
      isLoadingProducts,
      authStore,
      measure,
      products,
      renderLabel,
      descFraction,
      moment,
      ptBr,
    }
  }
})
